import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const DobleNacionalidadPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Doble nacionalidad" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Doble Nacionalidad"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Doble Nacionalidad"
      >
        <p>
        El fundamento legal lo encontramos en el artículo 144 de la Constitución Política de la República de
        Guatemala. Son guatemaltecos de origen, los nacidos en el territorio de la República de Guatemala,
        naves y aeronaves guatemaltecas y los hijos de padre o madre guatemaltecos, nacidos en el extranjero.
        </p>        
        <p>
        <strong>Toda persona nacida en el extranjero que sea hijo de padre o madre guatemalteco tiene el
          Derecho Constitucional a obtener la nacionalidad guatemalteca.</strong>
          </p>
          <p>
          ¡¡¡Llámenos y haga su cita hoy!!!
            </p>     
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default DobleNacionalidadPage

